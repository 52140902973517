import getCommonPageProps from '../common/init/get-common-props'
import Head from 'next/head'
import { removeUndefinedProperties } from '../common/lib/object'
import PageLayout from '../page/components/layout'
import { getHomePage } from './fetch-content'
import { commonPageProps, page as pageType } from '@proptypes'
import {
  localeToFbDomainVerificationCodeMap,
  fbDomainVerificationMetaKey
} from '#constants'
import { getPageConfig, HOME_PAGE } from '../common/page-config/page-config'
import { getLogger } from '#api/logger-with-context'

export const getStaticProps = async context => {
  const { locale } = context

  if (locale === 'localeRoot') {
    const logger = getLogger()
    logger.warn(
      { context },
      'Bad request: default locale identified in the request'
    )

    return {
      notFound: true,
      revalidate: 1
    }
  }

  const commonProps = await getCommonPageProps(locale, '/')
  const page = await getHomePage(locale)
  const fbDomainVerificationCode = localeToFbDomainVerificationCodeMap[locale]
  const gtmPageProps = getPageConfig(HOME_PAGE)
  return removeUndefinedProperties({
    props: {
      locale,
      page,
      ...commonProps,
      fbDomainVerificationCode,
      gtmPageProps
    },
    revalidate: 30
  })
}

const Home = ({ page, settings, fbDomainVerificationCode }) => {
  if (!page) {
    return null
  }
  return (
    <>
      {fbDomainVerificationCode ? (
        <Head>
          <meta
            key={fbDomainVerificationMetaKey}
            name={fbDomainVerificationMetaKey}
            content={fbDomainVerificationCode}
          />
        </Head>
      ) : null}
      <PageLayout slots={page.slots} settings={settings} isHomePage />
    </>
  )
}

Home.propTypes = {
  page: pageType.isRequired,
  ...commonPageProps
}

export default Home
