import Home, { getStaticProps } from '#src/home-page'

export { getStaticProps }

export default Home

import { datadogRum } from '@datadog/browser-rum'

datadogRum.init({
  applicationId: 'fef9799c-429f-4019-944e-3a539fd48d24',
  clientToken: 'pubbcdb3b89705cdd07dfe4f3c8f0097a8b',
  site: 'datadoghq.eu',
  service: 'web',
  env: 'production',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
})
